const initMobileNavbar = () => {
  const navbar = document.querySelector("nav#mobile");
  if (navbar) {
    const open = navbar.querySelector("#open_nav");
    const close = navbar.querySelector("#close_nav");
    const menu = navbar.querySelector(".nav-items");
    [open, close].forEach(element => {
      element.addEventListener("click", e => {
        open.classList.toggle("hidden");
        close.classList.toggle("hidden");
        menu.classList.toggle("visible");
      })
    })
  }
}

export { initMobileNavbar }
