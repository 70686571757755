const initFormMerger = () => {
  $("#title-location-form").bind('ajax:before', function() {
      const getKeywords = (form) => {
        let combinedKeywords = [];
        const keywords = document.querySelector('.selected-keywords');
        keywords.querySelectorAll('p').forEach(keyword => {
          combinedKeywords.push(keyword.innerText);
        })
        const newInput = document.createElement('input');
        newInput.setAttribute('name', 'job_filters[existing_keywords]');
        newInput.setAttribute('id', 'job_filters_existing_keywords');
        newInput.setAttribute('value', combinedKeywords.join(','));
        newInput.classList.add('hidden-field');
        form.insertAdjacentElement('beforeend', newInput);
      }
      getKeywords(this);
      $('#filters-form').find(":input").clone().addClass('hidden-field').appendTo(this);// could not find another way to prevent form submission.
    });
    $("#filters-form").bind('ajax:before', function() {
      const getKeywords = (form) => {
        let combinedKeywords = [];
        const keywords = document.querySelector('.selected-keywords');
        keywords.querySelectorAll('p').forEach(keyword => {
          combinedKeywords.push(keyword.innerText);
        })
        const newInput = document.createElement('input');
        newInput.setAttribute('name', 'job_filters[existing_keywords]');
        newInput.setAttribute('id', 'job_filters_existing_keywords');
        newInput.setAttribute('value', combinedKeywords.join(','));
        newInput.classList.add('hidden-field');
        form.insertAdjacentElement('beforeend', newInput);
      }
      getKeywords(this);
      $('#title-location-form').find(":input").clone().addClass('hidden-field').appendTo(this);// could not find another way to prevent form submission.
    });
    $("#title-location-form").bind('ajax:complete', function() {
      $(this).find(".hidden-field").remove();
    });
    $("#filters-form").bind('ajax:complete', function() {
      $(this).find(".hidden-field").remove();
    });
}
export { initFormMerger }
