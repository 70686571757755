  import 'waypoints/lib/noframework.waypoints.min.js';

  class Waypoints {
    constructor() {
      this.config = [];

      this.setNullOpacityForAnimateElements();
      this.generateAnimations();
    }

    setNullOpacityForAnimateElements() {
      this.config.forEach((config) => {
        const element = document.querySelector(config.selector);
        element.style.opacity = 0;
      })
    }

    generateAnimations() {
      this.config.forEach((config) => {
        const element = document.querySelector(config.selector);
        new Waypoint({
          element,
          offset: config.offset ? config.offset : '85%',
          handler: function(direction) {
            element.classList.add('animate__animated', config.animation);
          }
        })
      });
    }
  };

  export default Waypoints;
