  import SwiperApp, { Navigation } from 'swiper';

  class Swiper {
    constructor() {
      SwiperApp.use([Navigation]);
      
      this.swiper = new SwiperApp('.swiper-container', {
        direction: 'horizontal',
        speed: 700,
        loop: true,
        navigation: {
          nextEl: '.swiper-button__next',
          prevEl: '.swiper-button__prev',
        },
      })
    }
  };
  
  export default Swiper;
