const initJobShow = () => {
  $(document).on('click', '.fullscreen-job .back-to-results', closeModal)
};

const closeModal = () => {
  $('.fullscreen-job').remove();
  if (window.location.search.length > 0) {
    window.history.go(-2);
  } else {
    window.history.back();
  }
};

const initVisaForm = () => {
  $(document).on('change', '#visa_status_form select', function() {
    $('#visa_status_form input[type=submit]').click();
  });
};

const initCalculatorForm = () => {
  $(document).on('change', '#visa_calculator_form', function() {
    console.log('changed')
    $('#visa_calculator_form input[type=submit]').click();
  });
};

export { initJobShow, initVisaForm, initCalculatorForm }
