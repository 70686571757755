  class Magnific {
    constructor() {
      const el = $('.play-btn');
      if(el && el[0]) {
        this.magnific = el && el.magnificPopup({
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
          type: 'iframe',
          // closeOnContentClick: true,
          // closeBtnInside: false,
          mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
          image: {
            verticalFit: true
          },
          zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
          }
        });
      }
    }
  };
  
  export default Magnific;
