import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Rails from 'rails-ujs'
Rails.start()

import "./editor"

import { initMobileNavbar } from './mobile_navbar';
import { initCustomCheckbox } from './custom_checkbox';
// import { initTitleAutoSuggest, initLocationAutoSuggest, initRadiusDropdown } from './autosuggest';
import { initFormMerger } from './merge_forms';
import { initAutoSubmit } from './filter_auto_submit';
import { initShowFilters } from './show_filters';
import { initJobShow, initVisaForm, initCalculatorForm } from './job_show';
import { initSlickToolkit, initSlickBlogMenu } from './slick_carousel';
import { initCloseError } from './close_error';
// import { initJqueryToolTip } from './jquery_tooltip';
import { initLoadAddThis } from './load_add_this';
import { initFaq } from './faq_page';
import { initCalcFaq } from './calc_faq';
import { initSelect2 } from './init_select2';
import { initMapbox } from './init_mapbox';
import { initLoader } from './init_loader';
import { initSlider } from './init_slider';
import { initSwitcher } from './init_switcher';
import { initSwiper } from './init_swiper';
import 'magnific-popup';
import { initMagnific } from './init_magnific_popup';
import { initWaypoint } from './init_waypoint';
import { initFloatingNotice } from './floating_notice'

initMobileNavbar();
initCustomCheckbox();
initFormMerger();
initAutoSubmit();
// initLocationAutoSuggest();
// initRadiusDropdown();
// initTitleAutoSuggest();
initShowFilters();
initJobShow();
initVisaForm();
initSlickToolkit();
initSlickBlogMenu();
initCloseError();
// initJqueryToolTip();
initLoadAddThis();
initFaq();
initCalcFaq();
initSelect2();
initMapbox();
initLoader();
initSlider();
initSwitcher();
initCalculatorForm();
initSwiper();
initFloatingNotice();

$(document).ready(function (e) {
  initMagnific();
  initWaypoint();
});

$(document).on('ajax:complete', function (e) {
  initMapbox();
  initSlider();
});

const pathsWithAchorLinks = ["/faqs", "/translate-channels-for-slack", "/translate-privacy-policy"];

window.addEventListener('popstate', function(event) {
  const pathName = event.path[0].location.pathname;
  if (!pathsWithAchorLinks.includes(pathName) && (pathName.substring(0, 6) !== "/jobs/")) {
    location.reload();
  }
  // The popstate event is fired each time when the current history entry changes.
}, false);
