import moneyFormat from './money_format';

class Slider {
  constructor(element, options = {}) {
    this.$element = $(element);
    this.$background = this.$element.find('.slider__background');

    this.$inputMin = this.$element.find('input.input-min');
    this.$minSelector = this.$element.find('.slider__select_min');
    this.$minSelectorVal = this.$minSelector.find('.slider__value');
    this.$minProgressBar = this.$element.find('.slider__progress-bar_min');
    this.$inputMax = this.$element.find('input.input-max');
    this.$maxSelector = this.$element.find('.slider__select_max');
    this.$maxSelectorVal = this.$maxSelector.find('.slider__value');
    this.$maxProgressBar = this.$element.find('.slider__progress-bar_max');

    this.minVal = this.$inputMin.val();
    this.maxVal = this.$inputMax.val();
    this.width = this.$background.width() + 3.5;
    this.step = options.step || this.$element.data('step');
    this.min = options.min || this.$element.data('min');
    this.max = options.max || this.$element.data('max');
    this.stepPx = (this.width) / ((this.max - this.min) / this.step);

    this.minSelectOffset = (this.minVal - this.min) / this.step * this.stepPx;
    this.maxSelectOffset = (this.max - this.maxVal) / this.step * this.stepPx;

    this.moveMinSlider(this.minSelectOffset);
    this.moveMaxSlider(this.maxSelectOffset);

    this.initEvents();
  }

  initEvents() {
    this.initMinSelectorEvents();
    this.initMaxSelectorEvents();
  }

  initMaxSelectorEvents() {
    const startX = this.$background.offset().left + this.width;

    this.$maxSelector.on('touchmove', (e) => {
      this.moveMaxSlider(this.calcOffset(e.changedTouches[0].pageX, startX));
    });

    this.$maxSelector.on('touchend', (e) => {
      clearTimeout(this.delay);
      this.delay = setTimeout(() => {
        this.$inputMax.trigger('change');
      }, 800);
    });

    this.$maxSelector.mousemove((e) => {
      if (e.which == 1) {
        this.moveMaxSlider(this.calcOffset(e.clientX, startX));
      } else {
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
          this.$inputMax.trigger('change');
        }, 800);
      }
    });
  }

  moveMaxSlider(offset) {
    if (this.width - offset - this.minSelectOffset < this.stepPx * 2) {
      return;
    }
    this.maxSelectOffset = offset;
    const value = this.max - Math.round(offset / this.stepPx) * this.step;
    if (!this.changeVal('max', value, this.maxVal)) return;
    this.$maxSelectorVal.text(moneyFormat(value));
    this.$maxSelector.css('right', `${offset - 7}px`);
    this.$inputMax.val(value);
    this.setProgressBarWidth(offset, 'max');
    this.setValuePosition(offset, '$maxSelectorVal');
  }

  initMinSelectorEvents() {
    const startX = this.$background.offset().left;

    this.$minSelector.on('touchmove', (e) => {
      this.moveMinSlider(this.calcOffset(startX, e.changedTouches[0].pageX));
    });

    this.$minSelector.on('touchend', (e) => {
      clearTimeout(this.delay);
      this.delay = setTimeout(() => {
        this.$inputMin.trigger('change');
      }, 800);
    });

    this.$minSelector.mousemove((e) => {
      if (e.which == 1) {
        this.moveMinSlider(this.calcOffset(startX, e.clientX));
      } else {
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
          this.$inputMin.trigger('change');
        }, 800);
      }
   });
  }

  moveMinSlider(offset) {
    if (this.width - this.maxSelectOffset - offset <= this.stepPx) {
      return;
    }
    this.minSelectOffset = offset;
    const value = Math.round(offset / this.stepPx) * this.step + this.min;
    if (!this.changeVal('min', value, this.minVal)) return;
    this.$minSelectorVal.text(moneyFormat(value));
    this.$minSelector.css('left', `${offset - 7}px`);
    this.$inputMin.val(value);
    this.setProgressBarWidth(offset, 'min');
    this.setValuePosition(offset, '$minSelectorVal');
  }

  changeVal(type, value, prevVal) {
    if (prevVal === value) return;
    this[`${type}Val`] = value;
    return true;
  }

  setProgressBarWidth(offset, type) {
    const width = offset > this.width - 3.5 ? this.width - 3.5 : offset;
    this[`$${type}ProgressBar`].css('width', width);
  };

  calcOffset(start, current) {
    const position = current - start;

    if (position < 0) {
      return 0;
    } else if (position > this.width) {
      return this.width;
    } else {
      return position;
    }
  }

  setValuePosition(offset, element) {
    if (offset / this.width > 0.1)  {
      this[element].addClass('slider__value_center');
    } else {
      this[element].removeClass('slider__value_center');
    }
  }
};

export default Slider;
