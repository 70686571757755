const initLoadAddThis = () => {
  $(document).on('ajax:complete', function (e) {
    const regex = RegExp('/jobs/');
    const path = e.target.pathname;
    if (regex.test(path)) {
      addthis.layers.refresh();
    }
  });
}

export { initLoadAddThis }
