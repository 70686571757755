const initCalcFaq = () => {
  const faqContainer = document.querySelector(".calculator-faqs");
  if (faqContainer) {
    const parsedParams = new URLSearchParams(window.location.search);
    const itemId = parsedParams.get('item');
    if (itemId) {
      const el = document.getElementById(itemId);
      console.log(el);
      el.scrollIntoView(true);
    }
  }
}

export { initCalcFaq }
