const initFaq = () => {
  const faqContainer = document.querySelector(".faq-container");
  if (faqContainer) {
    const faqOpens = document.querySelectorAll(".faq-card .header");
    for (const open of faqOpens) {
      open.addEventListener("click", e => {
        const contentElement = open.nextElementSibling;
        contentElement.classList.toggle("closed");
      });
    }
  }
};

export { initFaq }
