import slick from "slick-carousel";

const initSlickToolkit = () => {
  $(document).ready(function(){
    $('.toolkit-gallery').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      infinite: false,
      responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              infinite: false
            }
          },
          {
            breakpoint: 860,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              centerMode: true,
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
    });
  });
}

const initSlickBlogMenu = () => {
  $(document).ready(function(){
      $('.categories-container').slick({
        responsive: [
          {
            breakpoint: 3000,
            settings: 'unslick'
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
              infinite: false,
            }
          }
          ]
      });
  });
}

export { initSlickToolkit, initSlickBlogMenu }
