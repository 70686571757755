import svgImage from '../../assets/images/x.svg';

const initAutoSubmit = () => {
  $(document).on('change', '.filter-container input[type!="text"]', submit);

  $(document).on('click', ".selected-keywords svg", {}, function(e) {
    $(this).parent().remove();
    submit();
  });

  $(document).on('keypress', '#job_filters_keyword', {}, function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which);
    if (keycode == '13') {
      const keywords = document.querySelector('.selected-keywords');
      const newElem = document.createElement('span');
      newElem.innerHTML = "<p>" + $(this).val() + "</p><img src='" + svgImage + "' />";
      keywords.appendChild(newElem);
      submit();
      $(this).val('');
      return false;
    }
  });
}

const submit = () => {
  $('.index-container input[type=submit]').click();
}

export { initAutoSubmit }
