import { DirectUpload } from "@rails/activestorage"
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min"
import Quill from 'quill/quill';

const MAX_FILE_SIZE = 2000000

Quill.register('modules/imageResize', ImageResize);

var icons = Quill.import('ui/icons')
icons['header'] = `<svg viewBox="0 0 18 18">
<path class="ql-fill" d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z"/>
</svg>` 
icons['italic'] = `<svg viewbox="0 0 18 18">
<line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line>
<line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line>
<line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line>
</svg>`
icons['list'] = `<svg viewbox="0 0 18 18">
<line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line>
<line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line>
<line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line>
<line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line>
<line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line>
<line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line>
</svg>`
icons['link'] = `<svg viewbox="0 0 18 18">
<line class="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line>
<path class="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path>
<path class="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path>
</svg>`
icons['clean'] = `<svg class="" viewbox="0 0 18 18">
<line class="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line>
<line class="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line>
<line class="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line>
<line class="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line>
<rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect>
</svg>`
icons['image'] = `<svg viewbox="0 0 18 18">
<rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect>
<circle class="ql-fill" cx="6" cy="7" r="1"></circle>
<polyline class="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline>
</svg>`
icons['bold'] = `<svg viewbox="0 0 18 18">
<path class="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path>
<path class="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path>
</svg>`
icons['underline'] = `<svg viewbox="0 0 18 18">
<path class="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path>
<rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect>
</svg>`

document.addEventListener("DOMContentLoaded", function(_event) {
    var insertImage = function (textEditor, fileUrl) {
        const range = textEditor.getSelection();
        textEditor.insertEmbed(range.index, 'image', fileUrl);
    };

    var uploadImage = function (textEditor, file) {
        var fd = new FormData();
        fd.append('blob', file);
      
        var upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')

        upload.create((error, blob) => {
            if (error) {
                console.log(error)
            } else {
                insertImage(
                    textEditor,
                    `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
                );
            }
        });
    };

    var importImage = function (textEditor) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();
      
        input.onchange = () => {
            const file = input.files[0];

            if (file.size > MAX_FILE_SIZE) {
                alert("Only support attachment files upto size 1MB!")
                return
            }
        
            // Ensure only images are uploaded
            if (/^image\//.test(file.type)) {
                uploadImage(textEditor, file);
            } else {
                alert('Only images allowed');
            }
        };
    };

    if (window.location.pathname.match(/posts/) && window.location.pathname.match(/admin/)) {
        var quill = new Quill('#editor', {
            modules: {
                toolbar: '#toolbar',
                imageResize: {
                    displaySize: true,
                    handleStyles: {
                        backgroundColor: 'black',
                        border: 'solid',
                        color: 'white'
                    },
                    modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
                },
            },
            theme: 'snow',
            value: document.querySelector('input[class=body-hidden]').value,
        });

        document.querySelector('form.edit_post').onsubmit = function () {
            var body = document.querySelector('input[class=body-hidden]');
            body.value = quill.root.innerHTML
        };

        quill.on('text-change', function(delta, oldDelta, source) {
            var event = new Event('change');
            var hiddenInput = document.querySelector('input[class=body-hidden]');
            hiddenInput.value = quill.root.innerHTML
            hiddenInput.dispatchEvent(event)
        });

        quill.getModule('toolbar').addHandler('image', () => {
            importImage(quill);
        });


    }
});
