import mapboxgl from 'mapbox-gl';

const fitMapToMarkers = (map, markers) => {
  const bounds = new mapboxgl.LngLatBounds();
  markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
  map.fitBounds(bounds, { padding: 70, maxZoom: 6, duration: 0 });
};

const initMapbox = () => {
  const mapElement = document.getElementById('mapbox-map');

  if (mapElement) { // only build a map if there's a div#map to inject into
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: 'mapbox-map',
      style: 'mapbox://styles/ukhired/ckcq5u1yb14ar1mo1dush4kin',
      center: [-3.716210, 54.904187],
      attributionControl: false,
      scrollZoom: false
    });
    const marker = JSON.parse(mapElement.dataset.markers);
    new mapboxgl.Marker({color: "var(--secondary)"})
      .setLngLat([ marker.lng, marker.lat ])
      .addTo(map);
    const boundingBox = [[-5.8544921875, 59.478568831926395], [2.021484375, 49.82380908513249]];
    map.fitBounds(boundingBox, {padding: 5, animate: false});
    map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'top-left');
    map.addControl(new mapboxgl.AttributionControl({compact: true}));
    // fitMapToMarkers(map, markers);
  }
};

export { initMapbox };
