const initShowFilters = () => {
  $(document).on('click', '.results-metadata .filter', showFilters)
}

const showFilters = () => {
  $('.results-metadata .filter').toggleClass('selected');
  const display = $('.filter-container').css('display');
  if (display == 'none') {
    $('.filter-container').css('display', 'block');
  } else {
    $('.filter-container').css('display', 'none');
  }
}

export { initShowFilters }
